import React from 'react';

const LogBook = React.lazy(() => import('../../../modules/dashboards/LogBook'));

export const dashboardConfig = [
  {
    // permittedRole: RoutePermittedRole.User,
    path: '/dashboards/logbook',
    element: <LogBook />,
  },
];
